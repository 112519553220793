import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { DataGrid } from '@material-ui/data-grid';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import { useCookies } from 'react-cookie';
import firebase from "firebase/app";
import Cookies from 'js-cookie';
import { useParams,  useLocation } from "react-router-dom";

const database = firebase.database();
const ViewLeaderboard = (props) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const classes = useStyles();
  const query = useQuery();
  const [loadTable, setLoading] = useState(true);

  const [rows, setRows] = useState([]);

  function compare( a, b ) {
    if ( a.score < b.score ){
      return 1;
    }
    if ( a.score > b.score ){
      return -1;
    }
    return 0;
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstname', headerName: 'First Name', width: 230 },
    { field: 'lastname', headerName: 'Last Name', width: 230 },
    { field: 'nickname', headerName: 'Nickname', width: 230 },
    { field: 'score', headerName: 'Score', width: 100 }
  ];
  const getScores = async () => {
    // console.log(auth);
    console.log(query.get("id"));
    try {
 
      // var currlead = database.ref("currleaderboard");
      // currlead
      //   .orderByValue()
      //   .once("value", function (snapshot) {
      //     currlb=snapshot.val();
      //   }).then(function () {
          var retarray = [];
          var sessions = database.ref("leaderboard").child(query.get("id"));
          sessions
            .orderByValue()
            .once("value", function (snapshot) {
              var cntr=0;
              snapshot.forEach(function (childSnapshot) {
                cntr=cntr+1;
                retarray.push({
                  id: cntr,
                  firstname: childSnapshot.child('firstName').val(),
                  lastname: childSnapshot.child('lastName').val(),
                  nickname: childSnapshot.child('nickName').val(),
                  score: childSnapshot.child('score').val()
                });
              });
            })
            .then(function () {
              retarray.sort(compare);
              var cntr=0;
              var s_retarray=[];
              retarray.forEach(function (obj){
                cntr=cntr+1;
                s_retarray.push({
                  id: cntr,
                  firstname: obj.firstname,
                  lastname: obj.lastname,
                  nickname: obj.nickname,
                  score: obj.score
                });
             
              });
            
              setRows(s_retarray);
              setLoading(false);
            });
        // });
     
    } catch (e) {
      
      //throw e;
      return false;
    }
  };

  useEffect(() => {
    
    if(loadTable===true){
      getScores();
    }
    
 
  });

  return (
    <main className={classes.content}>
    <div className={classes.appBarSpacer} />
    <Container maxWidth="lg" className={classes.container}>
      
      <Grid item xs={12}>
      <div style={{ height: 400, width: '100%',align: 'center' }}>
          {loadTable ? (
              <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
            ) : (
      <DataGrid rows={rows} columns={columns} pageSize={10} />
            )}
    </div>
            </Grid>
            <Grid item xs={12}>
         
          </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  </main>
  );
};



export default ViewLeaderboard;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
      PuzzleGame
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

