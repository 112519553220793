import "./App.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "./components/auth/Login";
import GameDash from "./components/game/gamedash";
import Game from "./components/game/game";
import { auth } from "./components/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Dashboard from "./components/dashboard/Dashboard";
import Signup from "./components/auth/Signup";
import gameprefs from "./components/game/PreferencesSingleton"
function App() {
  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <BrowserRouter>
        {user ? (
          <Switch>
            <Route path="/" component={Dashboard} />
            <Route path="/dashboard" component={Dashboard} />
            {/* <Route component={Redirect} /> */}
          </Switch>
        ) : gameprefs.getPreferenceData()!=[]?(
          <Switch>
              <Route path="/" exact component={GameDash} />
              <Route  path="/game" render={() => {window.location.href=`./game.html`}} />
            <Route path="/admin/login" component={Login} />
            <Route path="/admin/login" component={Login} />
            <Route path="/admin/signup" component={Signup} />
            <Route path="*">
              <Redirect to="/"></Redirect>
            </Route>
            {/* <Route component={Redirect} /> */}
          </Switch>
        ):(
          <Switch>
          <Route path="/" exact component={GameDash} />
          <Route  path="/game" render={() => {window.location.href="./game.html"}} />
        <Route path="/admin/login" component={Login} />
        <Route path="/admin/login" component={Login} />
        <Route path="/admin/signup" component={Signup} />
        <Route path="*">
          <Redirect to="/"></Redirect>
        </Route>
        {/* <Route component={Redirect} /> */}
      </Switch>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
