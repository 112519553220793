import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import Copyright from "../../copyright";
import DeleteIcon from "@material-ui/icons/Delete";
import { Redirect } from "react-router";
import firebase from "firebase/app";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import Backdrop from "@material-ui/core/Backdrop";
import ImageIcon from "@material-ui/icons/Image";
import Card from "@material-ui/core/Card";
import Image from "material-ui-image";
import CardContent from "@material-ui/core/CardContent";
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styles';
const database = firebase.database();
const ManageAdmin = () => {
  const classes = useStyles();
  const [credential,setCredential]=useState("");
  const [oldpasstext,setOldPass]=useState("");
  const [newpasstext,setnewPass]=useState("");
  const [rnewpasstext,setrnewPass]=useState("");
  
  const getCredential = async () => {
    try {
      var user = firebase.auth().currentUser;
      if (user) {
        setCredential(user.email);
      } else {
        setCredential("");;
      }
    } catch (e) {
      alert(e.message);
      //throw e;
      return false;
    }
  };
  const getASecureRandomPassword = async (oldPassword,newPassword,renewPassword) => {
    try {
      var user = firebase.auth().currentUser;
      var credential = firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        oldPassword
      );
      user.reauthenticateWithCredential(credential).then(function() {
        if(newPassword.toString()===renewPassword.toString()){
            user.updatePassword(newPassword.toString()).then(function() {
              alert('Password Changed!');
            }).catch(function(error) {
              alert(error.message);
            });
        }
        else{
          alert('New Password and Old Password do not match!');
        }
      }).catch(function(error) {
        
        return false;
      });
     
    } catch (e) {
      alert(e.message);
      //throw e;
      return false;
    }
  };
  useEffect(() => {
    getCredential();
  });
  const onChange_oP = (event) => {
    setOldPass(event.target.value);
  };
  const onChange_nP = (event) => {
    setnewPass(event.target.value);
  };
  const onChange_rnP = (event) => {
    setrnewPass(event.target.value);
  };
  return (
    <main className={classes.content}>
    <div className={classes.appBarSpacer} />
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
      <Grid item xs={14} md={4} lg={4}>
              <Typography variant="h4" align="left">Current Email</Typography>
              <Typography variant="h6" align="left">{credential}</Typography>
            </Grid>
            
      </Grid>
      <br/>
      <Grid item xs={12}>
              <Typography variant="h4" align="left">
                Change Password
              </Typography>
              <TextField
                required
                onChange={onChange_oP}
                id="standard-full-width"
                placeholder="Old Password"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
                 <TextField
                required
                onChange={onChange_nP}
                id="standard-full-width"
                placeholder="New Password"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
                 <TextField
                required
                onChange={onChange_rnP}
                id="standard-full-width"
                placeholder="Re-enter New Password"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
            <Button
              size="large"
              style={{ "min-height": "56px", width: "100%" }}
              onClick={()=>{
                var user = firebase.auth().currentUser;
                getASecureRandomPassword(oldpasstext,newpasstext,rnewpasstext);
               
              }}
              variant="contained"
              color="primary"
            >
              Change Password
            </Button>
          </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  </main>
  );
};

export default ManageAdmin;
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));
