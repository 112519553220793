import React, { useState } from "react";

import { Button } from "@material-ui/core";
import clsx from "clsx";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  Card,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import Copyright from "../../copyright";
import { DropzoneArea } from "material-ui-dropzone";
import Loader from "react-loader-spinner";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import firebase from "firebase/app";
import randomWords from 'random-words';
const database = firebase.database();
const storage = firebase.storage();
function guidGenerator() {
  const S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());

}
const CreateSession = () => {
  const classes = useStyles();
  const [sessionTitle, setsessionTitle] = useState("");
  const [sessionDifficulty, setsessionDifficulty] = useState([]);
  const [backgroundImage, setbackgroundImages] = useState([]);
  const [loader, setLoading] = useState(false);
  const [formMode, setformMode] = useState(true);
  const [newSession,setnewSession] = useState({

  });
  const [images, setImages] = useState([]);
  const onChange = (event) => {
    setsessionTitle(event.target.value);
  };
  const onChangeDifficulty = index => (event) => {
 
    var arr=sessionDifficulty;
    arr.splice(index,1,event.target.value);
    setsessionDifficulty(arr);
    console.log(sessionDifficulty);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const rows = [];
  
  const getDifficultyText = (text) => {
    if (text === 0) {
      return "Level 1";
    } else if (text === 1) {
      return "Level 2";
    } else if (text === 2) {
      return "Level 3";
    } else if (text === 3) {
      return "Level 4";
    } else {
      return "No Level";
    }
  };
  const createSession = async (images,sessionName,backgroundImage,sessionDifficulty) => {
    // console.log(auth);
    try {
      const uniId = guidGenerator().toString();
      const randomPassPhrase=randomWords()+"-"+randomWords();
      console.log(sessionDifficulty);
      await database.ref().child('sessions').child(randomPassPhrase).set({
        sessionPassPhrase: randomPassPhrase,
        sessionName: sessionName,
        sessionId: uniId,
        sessionDifficulty: sessionDifficulty,
        createdAt: new Date().toString().replace("(India Standard Time)","").replace("GMT+0530","")
      });
      setnewSession({
        sessionPassPhrase: randomPassPhrase,
        sessionName: sessionName,
        sessionId: uniId,
        sessionDifficulty: sessionDifficulty,
        createdAt: new Date().toString().replace("(India Standard Time)","").replace("GMT+0530","")
      });
  
      for(var x in images){
        const uploadTask = storage.ref('puzzlefiles/').child(images[x].name).put(images[x]);
  
        // await uploadTask.on('state_changed',
        //     (snapshot) => {
        //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //         console.log('Upload is ' + progress + '% done');
        //     },
        //     (error) => {
        //         // Handle unsuccessful uploads
        //         console.log("error:-", error)
        //     },
        //     () => {
        //         uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
        //           await database.ref().child('sessions').child(randomPassPhrase).child('puzzleImages').push(downloadURL);
        //         });
        //     }
        // )
        await uploadTask.then(
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
        }
    );
    await uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
      await database.ref().child('sessions').child(randomPassPhrase).child('puzzleImages').push(downloadURL);
    });
      }
      const uploadTask = storage.ref('backgroundfiles/').child(backgroundImage[0].name).put(backgroundImage[0]);
  
      await uploadTask.on('state_changed',
          (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
          },
          (error) => {
              // Handle unsuccessful uploads
              console.log("error:-", error)
          },
          () => {
              uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
                await database.ref().child('sessions').child(randomPassPhrase).child('backgroundImage').push(downloadURL);
              });
          }
      )
  
  
    } catch (e) {
      alert(e.message);
      //throw e;
      return false;
    }
  };
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {loader ? (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      ) : formMode ? (
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" align="left">
                Create New Session
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="left">
                Title
              </Typography>
              <TextField
                required
                onChange={onChange}
                id="standard-full-width"
                placeholder="Session Title"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="left">
              Upload Background
            </Typography>
            <DropzoneArea
              filesLimit={1}
              onChange={(files) => {
                setbackgroundImages(files);
              }}
            />
          </Grid>
       
          <Grid item xs={12}>
            <Typography variant="h5" align="left">
              Upload Puzzle Photos
            </Typography>
            <DropzoneArea
              filesLimit={10}
              onChange={(files) => {
                //reverse files array
                var arr=sessionDifficulty;
                var diffarr=[];
                if(arr.length===0){
                  for(var x in files){
                    console.log(x);
                    diffarr.push(0);
                  }
                  setsessionDifficulty(diffarr);
                }
                else{
                  if(arr.length>files.length){
                    for(var x in files){
                    
                      diffarr.push(arr[x]);
                    }
                    setsessionDifficulty(diffarr);
                  }
                  else{
                    for(var x in arr){
                    
                      diffarr.push(arr[x]);
                    }
                    for(var i=0;i<(files.length-arr.length);i++){
                      diffarr.push(0);
                    }
                    console.log(diffarr);
                    setsessionDifficulty(diffarr);
                  }
                 
                }
                // console.log(sessionDifficulty);
                setImages(files);
               
              }}
            />
          </Grid>
         {images.map((image,index)=>{
           
           return (
            <Grid item xs={12}>
    
            <Typography variant="h5" align="left">
              Change Difficulty - Image {index+1}
            </Typography>
            
            <Select
              defaultValue={0}
              style={{ "min-height": "56px", width: "100%" }}
              onChange={onChangeDifficulty(index)}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={0}>Difficulty Level 1</MenuItem>
              <MenuItem value={1}>Difficulty Level 2</MenuItem>
              <MenuItem value={2}>Difficulty Level 3</MenuItem>
              <MenuItem value={3}>Difficulty Level 4</MenuItem>
            </Select>
            <br/>
            <br/>
          </Grid>
           )
           
         })}
          <Grid item xs={12}>
            <Button
              size="large"
              style={{ "min-height": "56px", width: "100%" }}
              onClick={async () => {
                console.log(images);
                if(images.length===0){
                  return alert("No Puzzle Image Uploaded!");
                }
                if(sessionTitle===""){
                  return alert("No Session Title!");
                }
                if(backgroundImage.length===0){
                  return alert("No Background Image Uploaded!");
                }
                setLoading(true);
                await createSession(
                  images,
                  sessionTitle,
                  backgroundImage,
                  sessionDifficulty
                ).then(() => {
                  setLoading(false);
                  setformMode(false);
                });
              }}
              variant="contained"
              color="primary"
            >
              Create Session
            </Button>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      ) : (
        <Container maxWidth="lg" className={classes.container}>
          <Card>
            <CardContent>
              <Grid item xs={12}>
                <Typography variant="h4">New Session Created!</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="left">
                  Session ID
                </Typography>
                <TextField
                  required
                  value={newSession.sessionId}
                  disabled
                  id="standard-full-width"
                  placeholder="Session Title"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="left">
                  Passphrase
                </Typography>
                <TextField
                  required
                  value={newSession.sessionPassPhrase}
                  disabled
                  id="standard-full-width"
                  placeholder="Session Title"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Typography variant="h6" align="left">
                  Difficulty
                </Typography>
                <TextField
                  required
                  value={getDifficultyText(newSession.sessionDifficulty)}
                  disabled
                  id="standard-full-width"
                  placeholder="Session Title"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> */}
            </CardContent>
          </Card>

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      )}
    </main>
  );
};
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default CreateSession;














































// import React, { useState } from "react";

// import { Button } from "@material-ui/core";
// import clsx from "clsx";
// import {
//   Typography,
//   TextField,
//   Select,
//   MenuItem,
//   Card,
// } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
// import Grid from "@material-ui/core/Grid";
// import Box from "@material-ui/core/Box";
// import { Link } from "react-router-dom";
// import Copyright from "../../copyright";
// import { DropzoneArea } from "material-ui-dropzone";
// import Loader from "react-loader-spinner";
// import CardContent from "@material-ui/core/CardContent";
// import CardActions from "@material-ui/core/CardActions";
// import firebase from "firebase/app";
// import randomWords from 'random-words';
// const database = firebase.database();
// const storage = firebase.storage();
// function guidGenerator() {
//   const S4 = function () {
//       return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
//   };
//   return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());

// }
// const CreateSession = () => {
//   const classes = useStyles();
//   const [sessionTitle, setsessionTitle] = useState("");
//   const [sessionDifficulty, setsessionDifficulty] = useState(0);
//   const [backgroundImage, setbackgroundImages] = useState([]);
//   const [loader, setLoading] = useState(false);
//   const [formMode, setformMode] = useState(true);
//   const [newSession,setnewSession] = useState({

//   });
//   const [images, setImages] = useState([]);
//   const onChange = (event) => {
//     setsessionTitle(event.target.value);
//   };
//   const onChangeDifficulty = (event) => {
//     setsessionDifficulty(event.target.value);
//   };
//   const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
//   const rows = [];
  
//   const getDifficultyText = (text) => {
//     if (text === 0) {
//       return "Level 1";
//     } else if (text === 1) {
//       return "Level 2";
//     } else if (text === 2) {
//       return "Level 3";
//     } else if (text === 3) {
//       return "Level 4";
//     } else {
//       return "No Level";
//     }
//   };
//   const createSession = async (images,sessionName,backgroundImage,sessionDifficulty) => {
//     // console.log(auth);
//     try {
//       const uniId = guidGenerator().toString();
//       const randomPassPhrase=randomWords()+"-"+randomWords();
//       await database.ref().child('sessions').child(randomPassPhrase).set({
//         sessionPassPhrase: randomPassPhrase,
//         sessionName: sessionName,
//         sessionId: uniId,
//         sessionDifficulty: sessionDifficulty,
//         createdAt: new Date().toString()
//       });
//       setnewSession({
//         sessionPassPhrase: randomPassPhrase,
//         sessionName: sessionName,
//         sessionId: uniId,
//         sessionDifficulty: sessionDifficulty,
//         createdAt: new Date().toString()
//       });
  
//       for(var x in images){
//         const uploadTask = storage.ref('puzzlefiles/').child(images[x].name).put(images[x]);
  
//         await uploadTask.on('state_changed',
//             (snapshot) => {
//                 const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                 console.log('Upload is ' + progress + '% done');
//             },
//             (error) => {
//                 // Handle unsuccessful uploads
//                 console.log("error:-", error)
//             },
//             () => {
//                 uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
//                   await database.ref().child('sessions').child(randomPassPhrase).child('puzzleImages').push(downloadURL);
//                 });
//             }
//         )
//       }
//       const uploadTask = storage.ref('backgroundfiles/').child(backgroundImage[0].name).put(backgroundImage[0]);
  
//       await uploadTask.on('state_changed',
//           (snapshot) => {
//               const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//               console.log('Upload is ' + progress + '% done');
//           },
//           (error) => {
//               // Handle unsuccessful uploads
//               console.log("error:-", error)
//           },
//           () => {
//               uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
//                 await database.ref().child('sessions').child(randomPassPhrase).child('backgroundImage').push(downloadURL);
//               });
//           }
//       )
  
  
//     } catch (e) {
//       alert(e.message);
//       //throw e;
//       return false;
//     }
//   };
//   return (
//     <main className={classes.content}>
//       <div className={classes.appBarSpacer} />
//       {loader ? (
//         <Loader
//           style={{
//             position: "absolute",
//             left: "50%",
//             top: "50%",
//             transform: "translate(-50%, -50%)",
//           }}
//           type="Puff"
//           color="#00BFFF"
//           height={100}
//           width={100}
//           timeout={3000} //3 secs
//         />
//       ) : formMode ? (
//         <Container maxWidth="lg" className={classes.container}>
//           <Grid container spacing={3}>
//             <Grid item xs={12}>
//               <Typography variant="h4" align="left">
//                 Create New Session
//               </Typography>
//             </Grid>
//             <Grid item xs={12}></Grid>
//             <Grid item xs={12}>
//               <Typography variant="h5" align="left">
//                 Title
//               </Typography>
//               <TextField
//                 required
//                 onChange={onChange}
//                 id="standard-full-width"
//                 placeholder="Session Title"
//                 fullWidth
//                 margin="normal"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//               />
//             </Grid>
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="h5" align="left">
//               Change Difficulty
//             </Typography>

//             <Select
//               defaultValue={0}
//               style={{ "min-height": "56px", width: "100%" }}
//               onChange={onChangeDifficulty}
//               displayEmpty
//               className={classes.selectEmpty}
//               inputProps={{ "aria-label": "Without label" }}
//             >
//               <MenuItem value="">
//                 <em>None</em>
//               </MenuItem>
//               <MenuItem value={0}>Difficulty Level 1</MenuItem>
//               <MenuItem value={1}>Difficulty Level 2</MenuItem>
//               <MenuItem value={2}>Difficulty Level 3</MenuItem>
//               <MenuItem value={3}>Difficulty Level 4</MenuItem>
//             </Select>
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="h5" align="left">
//               Upload Puzzle Photos
//             </Typography>
//             <DropzoneArea
//               filesLimit={10}
//               onChange={(files) => {
//                 setImages(files);
//               }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="h5" align="left">
//               Upload Background
//             </Typography>
//             <DropzoneArea
//               filesLimit={1}
//               onChange={(files) => {
//                 setbackgroundImages(files);
//               }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Button
//               size="large"
//               style={{ "min-height": "56px", width: "100%" }}
//               onClick={async () => {
//                 setLoading(true);
//                 await createSession(
//                   images,
//                   sessionTitle,
//                   backgroundImage,
//                   sessionDifficulty
//                 ).then(() => {
//                   setLoading(false);
//                   setformMode(false);
//                 });
//               }}
//               variant="contained"
//               color="primary"
//             >
//               Create Session
//             </Button>
//           </Grid>
//           <Box pt={4}>
//             <Copyright />
//           </Box>
//         </Container>
//       ) : (
//         <Container maxWidth="lg" className={classes.container}>
//           <Card>
//             <CardContent>
//               <Grid item xs={12}>
//                 <Typography variant="h4">New Session Created!</Typography>
//               </Grid>
//               <Grid item xs={12}>
//                 <Typography variant="h6" align="left">
//                   Session ID
//                 </Typography>
//                 <TextField
//                   required
//                   value={newSession.sessionId}
//                   disabled
//                   id="standard-full-width"
//                   placeholder="Session Title"
//                   fullWidth
//                   margin="normal"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <Typography variant="h6" align="left">
//                   Passphrase
//                 </Typography>
//                 <TextField
//                   required
//                   value={newSession.sessionPassPhrase}
//                   disabled
//                   id="standard-full-width"
//                   placeholder="Session Title"
//                   fullWidth
//                   margin="normal"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <Typography variant="h6" align="left">
//                   Difficulty
//                 </Typography>
//                 <TextField
//                   required
//                   value={getDifficultyText(newSession.sessionDifficulty)}
//                   disabled
//                   id="standard-full-width"
//                   placeholder="Session Title"
//                   fullWidth
//                   margin="normal"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//             </CardContent>
//           </Card>

//           <Box pt={4}>
//             <Copyright />
//           </Box>
//         </Container>
//       )}
//     </main>
//   );
// };
// const drawerWidth = 240;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },
//   toolbar: {
//     paddingRight: 24, // keep right padding when drawer closed
//   },
//   toolbarIcon: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     padding: "0 8px",
//     ...theme.mixins.toolbar,
//   },
//   appBar: {
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//   },
//   appBarShift: {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   menuButton: {
//     marginRight: 36,
//   },
//   menuButtonHidden: {
//     display: "none",
//   },
//   title: {
//     flexGrow: 1,
//   },
//   drawerPaper: {
//     position: "relative",
//     whiteSpace: "nowrap",
//     width: drawerWidth,
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   drawerPaperClose: {
//     overflowX: "hidden",
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     width: theme.spacing(7),
//     [theme.breakpoints.up("sm")]: {
//       width: theme.spacing(9),
//     },
//   },
//   appBarSpacer: theme.mixins.toolbar,
//   content: {
//     flexGrow: 1,
//     height: "100vh",
//     overflow: "auto",
//   },
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   },
//   paper: {
//     padding: theme.spacing(2),
//     display: "flex",
//     overflow: "auto",
//     flexDirection: "column",
//   },
//   fixedHeight: {
//     height: 240,
//   },
// }));

// export default CreateSession;







