class GamePreferences {
    constructor() {
     this.preferencesData=[];
   
    }
    setPreferences(data) {
      this.preferencesData.push(...data);
    }
    getPreferenceData() {
      return this.preferencesData;
    }
  
  }
  
  const gameprefsingleton = new GamePreferences();
  
  Object.freeze(gameprefsingleton);
  export default gameprefsingleton;