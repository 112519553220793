module.exports= `<!DOCTYPE html>
<html>
    <head>
        <title>JIGSAW DELUXE</title>
        <link rel="stylesheet" href="css/reset.css" type="text/css">
        <link rel="stylesheet" href="css/main.css" type="text/css">
        <link rel="stylesheet" href="css/orientation_utils.css" type="text/css">
        <link rel="stylesheet" href="css/ios_fullscreen.css" type="text/css">
        <link rel='shortcut icon' type='image/x-icon' href='./favicon.ico' />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">

        <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui" />
	<meta name="msapplication-tap-highlight" content="no"/>

        <script type="text/javascript" src="js/jquery-3.2.1.min.js"></script>
        <script type="text/javascript" src="js/easeljs-NEXT.min.js"></script>
	<script type="text/javascript" src="js/tweenjs.js"></script>
        <script type="text/javascript" src="js/howler.min.js"></script>
        <script type="text/javascript" src="js/screenfull.js"></script>
        <script type="text/javascript" src="js/platform.js"></script>
        <script type="text/javascript" src="js/ios_fullscreen.js"></script>
        <script type="text/javascript" src="js/ctl_utils.js"></script>
        <script type="text/javascript" src="js/sprite_lib.js"></script>
        <script type="text/javascript" src="js/settings.js"></script>
        <script type="text/javascript" src="js/CLang.js"></script>
        <script type="text/javascript" src="js/CPreloader.js"></script>
        <script type="text/javascript" src="js/CMain.js"></script>
        <script type="text/javascript" src="js/CToggle.js"></script>
        <script type="text/javascript" src="js/CGfxButton.js"></script>
        <script type="text/javascript" src="js/CTextButton.js"></script>
        <script type="text/javascript" src="js/CMenu.js"></script>
        <script type="text/javascript" src="js/CGame.js"></script>
        <script type="text/javascript" src="js/CInterface.js"></script>
        <script type="text/javascript" src="js/CCreditsPanel.js"></script>
        <script type="text/javascript" src="js/CGameSettings.js"></script>
        <script type="text/javascript" src="js/CPiece.js"></script>
        <script type="text/javascript" src="js/CChooseImagePanel.js"></script>
        <script type="text/javascript" src="js/CButLevel.js"></script>
        <script type="text/javascript" src="js/CAreYouSurePanel.js"></script>
        <script type="text/javascript" src="js/CChooseDifficultyPanel.js"></script>
        <script type="text/javascript" src="js/CEndPanel.js"></script>
        <script type="text/javascript" src="js/CCTLText.js"></script>
        
    </head>
    <body ondragstart="return false;" ondrop="return false;" >
	<div style="position: fixed; background-color: transparent; top: 0px; left: 0px; width: 100%; height: 100%"></div>
          <script>
            $(document).ready(function(){
                     var oMain = new CMain({
                                            num_images:6,    //NUMBER OF IMAGES TO LOAD. YOU MUST ADD COHERENT NUMBER OF IMAGES IN /SPRITES FOLDER.
                                            audio_enable_on_startup:false, //ENABLE/DISABLE AUDIO WHEN GAME STARTS 
                                            enable_rotation:false, //ENABLE ROTATION PIECE BUTTON IN GAME
                                            enable_preview:true,  //ENABLE PREVIEW BUTTON IN GAME
                                            fullscreen:true, //SET THIS TO FALSE IF YOU DON'T WANT TO SHOW FULLSCREEN BUTTON
                                            check_orientation:true     //SET TO FALSE IF YOU DON'T WANT TO SHOW ORIENTATION ALERT ON MOBILE DEVICES
                                           });

                    $(oMain).on("start_session", function(evt) {
                            if(getParamValue('ctl-arcade') === "true"){
                                parent.__ctlArcadeStartSession();
                            }
                            alert('START SESSION');
                    });
                     
                    $(oMain).on("end_session", function(evt) {
                           if(getParamValue('ctl-arcade') === "true"){
                               parent.__ctlArcadeEndSession();
                           }
                           alert('END SESSION');
                    });

                   $(oMain).on("save_score", function (evt, iScore) {
                        if (getParamValue('ctl-arcade') === "true") {
                            parent.__ctlArcadeSaveScore({score: iScore});
                        }
                        alert('SAVE SCORE');
                    });

                    $(oMain).on("show_interlevel_ad", function (evt) {
                        if (getParamValue('ctl-arcade') === "true") {
                            parent.__ctlArcadeShowInterlevelAD();
                        }
                    });

                    $(oMain).on("share_event", function (evt, iScore) {
                        if (getParamValue('ctl-arcade') === "true") {
                            parent.__ctlArcadeShareEvent({img: TEXT_SHARE_IMAGE,
                                title: TEXT_SHARE_TITLE,
                                msg: TEXT_SHARE_MSG1 + iScore
                                        + TEXT_SHARE_MSG2,
                                msg_share: TEXT_SHARE_SHARE1
                                        + iScore + TEXT_SHARE_SHARE1});
                        }
                      
                        
                     
                    });
                     
                    if(isIphone()){ 
                        setTimeout(function(){sizeHandler();},200); 
                    }else{ 
                        sizeHandler(); 
                    }
           });

        </script>
        
        <div class="check-fonts">
            <p class="check-font-1">1</p>
        </div> 
        
        <canvas id="canvas" class='ani_hack' width="1920" height="1080"> </canvas>
         <div data-orientation="landscape" class="orientation-msg-container"><p class="orientation-msg-text">Please rotate your device</p></div>
        <div id="block_game" style="position: fixed; background-color: transparent; top: 0px; left: 0px; width: 100%; height: 100%; display:none"></div>
        
    </body>
</html>`;
