import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Select,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import Copyright from "../../copyright";
import DeleteIcon from "@material-ui/icons/Delete";
import { Redirect } from "react-router";
import firebase from "firebase/app";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import Backdrop from "@material-ui/core/Backdrop";
import ImageIcon from "@material-ui/icons/Image";
import Card from "@material-ui/core/Card";
import Image from "material-ui-image";
import CardContent from "@material-ui/core/CardContent";
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styles';
const database = firebase.database();

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};
const DashboardHome = () => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [rows, setRows] = useState([]);
  const [currlead, setCurrLead] = useState("");
  const [sellead, setSelLead] = useState("");
  const [loadTable, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  var [viewlead,setViewLead]=useState("/admin/viewLeaderboard?id="+sellead);
  const [currSelected, setcurrSelected] = useState({
    backgroundImage: [],
    puzzleImages: [],
  });
  const imageStyle = {
    width: "500px",
    height: "500px",
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onChangeLeaderboard = (event) => {
    setSelLead(event.target.value);
    setViewLead("/admin/viewLeaderboard?id="+event.target.value)
    // var sessions = database.ref("currleaderboard");
    // sessions
    //   .set(event.target.value);
      
  }
  const getSessions = async () => {
    // console.log(auth);

    try {
      var retarray = [];

      var sessions = database.ref("sessions");
      sessions
        .orderByValue()
        .once("value", function (snapshot) {
          snapshot.forEach(function (childSnapshot) {
            retarray.push(childSnapshot.val());
          });
        })
        .then(function () {
          var currlead = database.ref("currleaderboard");
          currlead
            .orderByValue()
            .once("value", function (snapshot) {
             
            
              // setCurrLead(snapshot.val());
              // setSelLead(snapshot.val());
            })
            .then(function () {
          setRows(retarray);
      
          setLoading(false);
            });
          
          
        });
    } catch (e) {
      alert(e.message);
      //throw e;
      return false;
    }
  };
  const deleteSession = async (id) => {
    // console.log(auth);

    try {
      var sessions = database.ref("sessions/" + id);
      sessions.remove();
    } catch (e) {
      alert(e.message);
      //throw e;
      return false;
    }
  };
  const getDifficultyText = (text) => {
    if (text === 0) {
      return "Level 1";
    } else if (text === 1) {
      return "Level 2";
    } else if (text === 2) {
      return "Level 3";
    } else if (text === 3) {
      return "Level 4";
    } else {
      return "No Level";
    }
  };
  const parseDifficulty = (text) => {

    return (
      <ul>
        {text.map((txt,index)=>{
          return (
            <li><b>Image {parseInt(index)+1}</b>:{getDifficultyText(txt)}</li>
          )
        })}
        </ul>
    )
  };
  useEffect(() => {
 
      getSessions();
      if(typeof rows[0]!='undefined' && sellead===""){
        setSelLead(rows[0].sessionPassPhrase);
        setViewLead("/admin/viewLeaderboard?id="+rows[0].sessionPassPhrase);
      }
  });

  // console.log(rows);
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={open}>
            <div
             
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Card className={classes.root}>
                <CardContent>
                  <h2 id="spring-modal-title">Game Session Images</h2>
                  <h4 id="spring-modal-description">Background Image</h4>
                  <Image
                    style={{
                      height:"20vh",
                      width: "20vw"
                    }}
                    src={Object.values(currSelected.backgroundImage)[0]}
                  ></Image>
                  <h4 id="spring-modal-description">Puzzle Images</h4>
                  <AwesomeSlider cssModule={AwesomeSliderStyles}>
                  {Object.values(currSelected.puzzleImages).map((e) => (
                    <div data-src={e}></div>
                  ))}
                  </AwesomeSlider>
                  
                </CardContent>
              </Card>
            </div>
          </Fade>
        </Modal>
        <Grid container spacing={3}>
          {/* Chart */}

          {/* Recent Deposits */}
          <Grid item xs={14} md={2} lg={2}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/admin/createSession"
            >
              CREATE SESSION
            </Button>
          </Grid>
          <Grid item xs={14} md={4} lg={4}>
          <Typography variant="h5">Leaderboard Options</Typography>
          {loadTable ? (
              <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
            ) : (
          <Select
              defaultValue={typeof rows[0]==='undefined'?"":rows[0].sessionPassPhrase}
              style={{ "min-height": "56px", width: "50%" }}
              onChange={onChangeLeaderboard}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
            >
              {
                rows.map((e)=>(
                 
                  <MenuItem value={e.sessionPassPhrase}>{e.sessionPassPhrase}</MenuItem>
                ))
              }
              
            </Select>
            )}
            <br/>
            <br/>
                <Button
              variant="contained"
              color="secondary"
              onClick={()=>{
                if(window.confirm('Are you sure?')){
                  var leaderboard = database.ref("leaderboard").child(sellead);
                  leaderboard.remove();
                  window.alert('Leaderboard Clear!');
                }
                else{

                }
        

              }}
            >
             CLEAR LEADERBOARD
            </Button>
            <br/>
            <br/>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={viewlead}
            >
              VIEW LEADERBOARD
            </Button>
          </Grid>
          
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Grid item xs={14} md={4} lg={4}>
              <Typography variant="h4">Current Active Sessions</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {loadTable ? (
              <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
            ) : (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Session ID</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Passphrase</TableCell>
                      <TableCell>Created at</TableCell>
                      <TableCell>Difficulty</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {console.log(rows)} */}
                    {rows.map((row) => (
                      <TableRow key={row.sessionId}>
                        <TableCell component="th" scope="row">
                          {row.sessionId}
                        </TableCell>
                        <TableCell>{row.sessionName}</TableCell>
                        <TableCell>{row.sessionPassPhrase}</TableCell>
                        <TableCell>{row.createdAt}</TableCell>
                        <TableCell>
                          {parseDifficulty(row.sessionDifficulty)}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              deleteSession(row.sessionPassPhrase);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            aria-label="image"
                            onClick={() => {
                              handleOpen();
                              setcurrSelected(row);
                              console.log(currSelected);
                            }}
                          >
                            <ImageIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  );
};

export default DashboardHome;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));
