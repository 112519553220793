import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

firebase.initializeApp({
  //* Config
  apiKey: "AIzaSyDOCCrfTrLcaTqZRbSul5p0zLS2mGQ3HP4",
  authDomain: "puzzlegame-50069.firebaseapp.com",
  projectId: "puzzlegame-50069",
  storageBucket: "puzzlegame-50069.appspot.com",
  messagingSenderId: "874377096764",
  appId: "1:874377096764:web:b6891f9d5e4b6d65a3dd39",
  measurementId: "G-5FFNZT0P2H",
  databaseURL: "https://puzzlegame-50069-default-rtdb.firebaseio.com/"
});

const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
const database = firebase.database();
const storage = firebase.storage();
// const googleAuthProvider = firebase.auth.GoogleAuthProvider();

const signInWithGoogle = () => {
  console.log(auth);
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
    // auth.signInWithGoogle(provider);
    return true;
  } catch (e) {
    alert(e.message);
    // throw e;
    return false;
  }
};

const signInWithEmail = (email, pass) => {
  // console.log(auth);
  try {
    auth.signInWithEmailAndPassword(email, pass).then(function() {
     
    }).catch(function(error) {
      alert(error.message);
    });
  } catch (e) {
    alert(e.message);
    //throw e;
    return false;
  }
};



const signOut = () => {
  auth.signOut();
};

export { auth, database, storage, signInWithGoogle, signInWithEmail, signOut };
