import React, { useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';
import CssBaseline from "@material-ui/core/CssBaseline";
import { DataGrid } from '@material-ui/data-grid';
import Link from "@material-ui/core/Link";
import Loader from "react-loader-spinner";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import firebase from "firebase/app";
import gamepreferences from './PreferencesSingleton';
import { makeStyles } from "@material-ui/core/styles";
import { signInWithEmail } from "../firebase";
import { useCookies } from 'react-cookie';
import { useForm } from "react-hook-form";
const database = firebase.database();
const GameDash = (props) => {

  const classes = useStyles();
  const [cookies_fn, setCookieFN] = useCookies(['firstName']);
  const [cookies_ln, setCookieLN] = useCookies(['lastName']);
  const [cookies_nn, setCookieNN] = useCookies(['nickName']);
  const [cookies_pp, setCookiePP] = useCookies(['passPhrase']);
  const [cookies_pl, setCookiePL] = useCookies(['played']);
  const [loadTable, setLoading] = useState(true);
  const [firstName, setfirstName] = useState("");
  const [rows, setRows] = useState([]);
  const [lastName, setlastName] = useState("");
  const [nickName, setnickName] = useState("");
  const [passphrase, setpassphrase] = useState("");
  function compare( a, b ) {
    if ( a.score < b.score ){
      return 1;
    }
    if ( a.score > b.score ){
      return -1;
    }
    return 0;
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstname', headerName: 'First Name', width: 230 },
    { field: 'lastname', headerName: 'Last Name', width: 230 },
    { field: 'nickname', headerName: 'Nickname', width: 230 },
    { field: 'score', headerName: 'Score', width: 100 }
  ];
  const getScores = async () => {
    // console.log(auth);

    try {
      var currlb=Cookies.get('passPhrase');
      // var currlead = database.ref("currleaderboard");
      // currlead
      //   .orderByValue()
      //   .once("value", function (snapshot) {
      //     currlb=snapshot.val();
      //   }).then(function () {
          var retarray = [];
          var sessions = database.ref("leaderboard").child(currlb);
          sessions
            .orderByValue()
            .once("value", function (snapshot) {
              var cntr=0;
              snapshot.forEach(function (childSnapshot) {
                cntr=cntr+1;
                retarray.push({
                  id: cntr,
                  firstname: childSnapshot.child('firstName').val(),
                  lastname: childSnapshot.child('lastName').val(),
                  nickname: childSnapshot.child('nickName').val(),
                  score: childSnapshot.child('score').val()
                });
              });
            })
            .then(function () {
              retarray.sort(compare);
              var cntr=0;
              var s_retarray=[];
              retarray.forEach(function (obj){
                cntr=cntr+1;
                s_retarray.push({
                  id: cntr,
                  firstname: obj.firstname,
                  lastname: obj.lastname,
                  nickname: obj.nickname,
                  score: obj.score
                });
             
              });
            
              setRows(s_retarray);
              setLoading(false);
            });
        // });
     
    } catch (e) {
      
      //throw e;
      return false;
    }
  };
  const onChangeFirstName = (event) => {
    setfirstName(event.target.value);
  };
  const onChangeLastName = (event) => {
    setlastName(event.target.value);
  };
  const onChangeNickName = (event) => {
    setnickName(event.target.value);
  };
  const onChangePassPhrase = (event) => {
    setpassphrase(event.target.value);
  };
  function setGamePreferences(firstName,lastName,nickName,passPhrase){
    try{
      // if(!JSON.parse(Cookies.get('played')).includes(passPhrase)){
        setCookieFN('firstName',firstName,{path:"/",  maxAge: 1000000});
        setCookieLN('lastName',lastName,{path:"/",  maxAge: 1000000});
        setCookieNN('nickName',nickName,{path:"/",  maxAge: 1000000});
        setCookiePP('passPhrase',passPhrase,{path:"/",  maxAge: 1000000});
          // gamepreferences.setPreferences([firstName,lastName,nickName,passPhrase]);
        window.location.href="/game";
      // }
      // else{
      //   alert('You cannot play more. You have exhausted all your chances.');
      // }
    }
    catch(e){
      setCookieFN('firstName',firstName,{path:"/",  maxAge: 1000000});
      setCookieLN('lastName',lastName,{path:"/",  maxAge: 1000000});
      setCookieNN('nickName',nickName,{path:"/",  maxAge: 1000000});
      setCookiePP('passPhrase',passPhrase,{path:"/",  maxAge: 1000000});
        // gamepreferences.setPreferences([firstName,lastName,nickName,passPhrase]);
      window.location.href="/game";
    }
  }
  useEffect(() => {
    if(loadTable===true){
      getScores();
    }
    
 
  });

  return (
    <>
      <Container component="main" maxWidth="xs">
     
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Enter the Passphrase to join a game!
          </Typography>
    
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="fname"
              label="First Name"
              onChange={onChangeFirstName}
              autoFocus
              name="firstName"
              type="text"
            />
          <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="lname"
              required
              label="Last Name"
              onChange={onChangeLastName}
              autoFocus
              name="lastName"
              type="text"
            />
              <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="nname"
              required
              label="Nickname"
              onChange={onChangeNickName}
              autoFocus
              name="nickname"
              type="text"
            />
              <TextField
              variant="outlined"
              margin="normal"
              id="pp"
              fullWidth
              required
              label="Passphrase"
              onChange={onChangePassPhrase}
              autoFocus
              name="passphrase"
              type="text"
            />
            <Button
              onClick={()=>{
                  setGamePreferences(firstName,lastName,nickName,passphrase);
              }}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Enter the Game
            </Button>
              <br/>
              <br/>
              {loadTable ? (
                <Container></Container>
            ) : (
              <Typography component="h1" variant="h5">
              Leaderboard
             </Typography>
            )}
             
   
    
        </div>
        </Container>
        <Container maxWidth="md">
        <div style={{ height: 400, width: '100%',align: 'center' }}>
          {loadTable ? (
              <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
            ) : (
      <DataGrid rows={rows} columns={columns} pageSize={10} />
            )}
    </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );

  // function loginWithGoogle() {
  //   signInWithGoogle();

  //   // if (response) {
  //   //   props.history.replace("/");
  //   // }
  // }

 
};

export default GameDash;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
      PuzzleGame
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
